





































































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  CmsCategoryType,
  TrainDto,
  TrainRecordCreateOrUpdateDto, TrainRecordDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import ExamPaperDetail from "@/views/train/trainRecord/component/examPaperDetail.vue";
import moment from "moment";


interface ICategoryTypeSelectItem {
  type: CmsCategoryType;
  label: string;
}

@Component({
  components: {SimpleUploadImage, Ueditor, MultipleUploadFile, ExamPaperDetail},
})
export default class CreateCmsCategory extends Vue {
  @Ref() readonly dataForm!: ElForm;
  submitForm: TrainRecordCreateOrUpdateDto = {
    /** 培训ID */
    trainId: undefined,

    /** 培训报名ID */
    trainApplyId: undefined,

    /** 报名用户Id */
    applyUserId: undefined,

    /** 志愿者Id */
    volunteerId: undefined,

    /** 培训感悟 */
    feelings: undefined,

    /**  */
    savedAttachments: [],

    /**  */
    id: 0
  };

  dataId!: number;

  form: TrainRecordDto = {
    train: {
      trainType: {}
    },
    applyUser: {},
    volunteer: {}
  };
  // 培训地点
  trainAddress: undefined;

  cmsCategoryType = CmsCategoryType.Content;

  trainList: TrainDto[] = [];

  loaded = false;

  get hostType() {
    return AttachmentHostType.TrainRecord;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number((this as any).$route.params.id);
      api.trainRecord.get({id: Number(this.$route.params.id)}).then((res) => {
        this.form = {...res};
        (this as any).form.train.trainStartTime = (this as any).getTime(res.train?.trainStartTime);
        (this as any).form.train.trainEndTime = (this as any).getTime(res.train?.trainEndTime);
        (this as any).trainAddress = (res.train!.province?.provinceName || "") + (res.train!.city?.cityName || "") + (res.train!.area?.areaName || "") + (res.train!.address || "") + '';
        if (res.train?.trainMethod === 'Offline') {
          (this as any).form.train.trainMethod = '线下'
        } else if (res.train?.trainMethod === 'Online') {
          (this as any).form.train.trainMethod = '线上'
        }
        this.loaded = true;
      });
    }
  }

  getDataList() {
    api.train.getAll({maxResultCount: 65535}).then((res) => {
      this.trainList = res.items!;
    });
  }

  getTime(time: any) {
    return moment(time).format('YYYY-MM-DD HH:mm')
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.submitForm.trainId = this.form.trainId;
        this.submitForm.trainApplyId = this.form.trainApplyId;
        this.submitForm.applyUserId = this.form.applyUserId;
        this.submitForm.volunteerId = this.form.volunteerId;
        this.submitForm.id = this.dataId;
        this.submitForm.feelings = this.form.feelings;
        this.submitForm.auditFlowId = this.form.auditFlowId;
        this.submitForm.audit = this.form.audit;
        this.submitForm.auditStatus = this.form.auditStatus;

        await api.trainRecord.update({
          body: this.submitForm,
        });
        this.$router.back();
        this.$message.success("更新成功");
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  get pageHeadTitle() {
    return "培训记录编辑";
  }

  roleRule = {
    feelings: [
      {
        required: true,
        message: "培训感悟 必填",
        trigger: "blur",
      },
    ],
  };
}
